import store from '@/store';
import { computed } from '@vue/composition-api';
import { useStoreModule } from "@/composition/useStoreModule";
import { managersStoreModule } from "@/store/businessUsers/managersStore.module";

const STORE_MODULE_NAME = 'managersStoreModule';

export function useManagers() {

    useStoreModule(STORE_MODULE_NAME, managersStoreModule);

    const fetchManagers =  async (params) => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchManagers`, params);
    };

    const managers = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_MANAGERS`];
    })

    function getManagerName(id) {
        return managers?.value?.find(el => parseInt(el.profileId) === parseInt(id))?.fullName || id
    }

    return { managers, fetchManagers, getManagerName };

}